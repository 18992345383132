import {useEffect, useMemo, useState} from "react";
import {useSearchParams} from "react-router-dom";
import config from "src/config";
import { CompleteRequest} from "../workers/types";
import { dispatch } from "src/workers/common-worker";
import { Nullable } from "src/workers/types";
import { Grid } from "@mui/material";
import '../styles/Index.css'
import { Trans, useTranslation } from 'react-i18next';
import FailContent from "./FailContent";

export default function Success() {
    const [isCompleted, setIsCompleted] = useState<boolean>()
    const [isIsSucceeded, setIsSucceeded] = useState<boolean>()
    const { t, i18n } = useTranslation();
    const [searchParams] = useSearchParams();

    const worker: Worker = useMemo(
        () => new Worker(new URL("../workers/complete-worker.ts", import.meta.url)),
        []
    );
    const paymentsServiceUrl: string =  config.apis.paymentServiceUrl;
    const tokenMediatorUrl: string =  config.apis.tokenMediatorUrl;

    const loadFavicon = () => {
        const link = document.createElement('link');
        link.id = 'favicon';
        link.rel = 'shortcut icon';
        document.head.appendChild(link);

        (link as HTMLLinkElement).href = require(`../assets/freenet-favicon.ico`);
    }

    useEffect(() => {
        loadFavicon();

        if (Worker) {
            const intentId = searchParams.get("intent_id") || "";
            const tenantId = searchParams.get("t_id") || "";
            const testMode = searchParams.get("test_mode") || "";
            const isTestMode = testMode === "true";

            const request: CompleteRequest = {
                tokenMediatorUrl: tokenMediatorUrl,
                paymentsServiceUrl: paymentsServiceUrl,
                intentId: intentId,
                testMode: isTestMode,
                tenantId: tenantId
            };

            dispatch<CompleteRequest, any>(worker, request)
                .then((completed: Nullable<boolean>) => {
                    setIsCompleted(true);
                    
                    if (!completed) {
                        setIsSucceeded(false);
                        return;
                    }
                    
                    setIsSucceeded(true);

                    if(window.opener){
                        window.opener.postMessage({finalize: true, success: true}, "*");
                        window.close();
                    }
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    return (
        <div>
            {!isCompleted && (
                <div className="loading"></div>
            )}

            {isCompleted && (
                <div>
                    {isIsSucceeded && (
                        <Grid container justifyContent="center" alignItems="center" style={{height: "100%", position:"absolute"}}>
                            <Grid item xs={6} textAlign={"center"}>
                                <img width={64} src={require('../assets/success.png')} alt=""/>
                                <h2 className="primary-font-color">{t("successPurchase")}</h2>
                                <p className="primary-font-color">{t("successPurchaseMessage")}</p>
                            </Grid>
                        </Grid>
                    )}

                    {!isIsSucceeded && (
                        <div><FailContent /></div>
                    )}
                </div>
            )}
        </div>
)
}