import { createTheme } from '@mui/material/styles';
import TruphoneTheme from ".";
import TruphoneColors from "./colors";


const palette = Object.assign({}, TruphoneTheme.palette, {
    primary: {
        main: '#2596be',
    },
    sidebar: {
        main: '#01313f',
        action: {
            selected: '#fff'
        }
    }
});

const theme = {
    ...TruphoneTheme,
    palette,
    overrides: {
        MuiTableHead: {
            root: {
                backgroundColor: TruphoneColors.white
            }
        },
        MuiTable: {
            root: {
                backgroundColor: TruphoneColors.red
            }
        },
        MuiBox: {
            root:{
                marginTop: "0px",
                marginBottom: "0px",
                backgroundColor: TruphoneColors.red
            }
        },
        MuiListItem: {
            root: {
                "&$selected, &$selected:hover, &$button:hover": {
                    backgroundColor: "#58D3F7"
                }
            }
        },
        /*MuiButton: {
          root: {
            backgroundColor: "#2596be",
            color: "#2596be"
          }
        }*/
    }
};

export default createTheme(theme);