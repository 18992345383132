import { Route } from "react-router-dom";
import MsisdnForm from "../components/MsisdnForm";
import Success from "../components/Success";
import Fail from "../components/Fail";

const ZahlEinfachRoutes = (
    <>
        <Route path={'/msisdn'} element={ <MsisdnForm/> }></Route>
        <Route path={'/msisdn/success'} element={ <Success/> }></Route>
        <Route path={'/msisdn/fail'} element={ <Fail/> }></Route>
    </>
)

export default ZahlEinfachRoutes;