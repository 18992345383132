import {CSSProperties, useEffect, useLayoutEffect, useRef, useState} from "react";
import {PaymentRequest, Stripe, StripeElementLocale} from "@stripe/stripe-js/types/stripe-js";
import {Elements, useStripe} from "@stripe/react-stripe-js";
import config from "src/config";
import {loadStripe} from "@stripe/stripe-js";
import {
    ButtonsComponentPage,
    IntentChallenged,
    isPaymentRequestData,
    isPaymentRequestFinalize,
    isPaymentRequestUpdate,
    MsisdnUpdate,
    PaymentCompleted,
    PaymentRequestData,
    PaymentRequestFinalize,
    PaymentRequestUpdate
} from "./Types";
import i18n from "i18next";
import {Button} from "@mui/material";

export default function WalletButtonStripe(buttonsComponentPage: ButtonsComponentPage) {

    const [stripe, setStripe] = useState<Stripe | null>(null);
    const [stripeInitialized, setStripeInitialized] = useState<boolean>();

    useEffect(() => {
        const initStripe = async () => {
            let stripeResult = await loadStripe(config.gateway.apiKeys[buttonsComponentPage.providerAccount], {locale: ("en" as StripeElementLocale)})
            setStripe(stripeResult);
        }

        initStripe();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!stripe) {
            return;
        }

        setStripeInitialized(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stripe]);

    return (
        <div>
            {stripeInitialized && (
                <Elements stripe={stripe}>
                    <WalletButton {...buttonsComponentPage}></WalletButton>
                </Elements>
            )}
        </div>
    );
}

function WalletButton(buttonsComponentPage: ButtonsComponentPage) {
    const stripe = useStripe();

    const [context, setContext] = useState<unknown>();
    const [style, setStyle] = useState<CSSProperties>();

    const paymentIntentApi = 'intent';
    const googlePayPaymentButton = 'google_pay';
    const applePayPaymentButton = 'apple_pay';

    const [isWalletAvailable, _setIsWalletAvailable] = useState<boolean>(false);
    const isWalletAvailableRef = useRef(isWalletAvailable)
    const setIsWalletAvailable = data => {
        isWalletAvailableRef.current = data;
        _setIsWalletAvailable(data);
    };

    const [isWalletCheckingFinished, setIsWalletCheckingFinished] = useState<boolean>(false);

    const [walletType, setWalletType] = useState<'apple_pay' | 'google_pay'>();

    const secretRef = useRef("");
    const setSecret = data => {
        secretRef.current = data;
    };

    const [paymentRequest, _setPaymentRequest] = useState<PaymentRequest>();
    const paymentRequestRef = useRef(paymentRequest);
    const setPaymentRequest = data => {
        paymentRequestRef.current = data;
        _setPaymentRequest(data);
    };

    //subscribe to window events
    useLayoutEffect(() => {
        window.addEventListener('message', walletMessageHandler);
        return () => {
            window.removeEventListener('message', walletMessageHandler);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //check wallet availability
    useEffect( () => {
        if (!stripe) {
            return;
        }

        if (isWalletCheckingFinished) {
            return;
        }

        function mapGatewayAccount(responseGatewayAccount){
            if(responseGatewayAccount === "UK"){
                return "GB";
            }

            return responseGatewayAccount;
        }

        const checkWalletAvailability = function () {
            const pr = stripe.paymentRequest({
                country: mapGatewayAccount(buttonsComponentPage.providerAccount),
                currency: buttonsComponentPage.currency,
                total: {
                    label: buttonsComponentPage.description,
                    amount: buttonsComponentPage.amount,
                    pending: true
                },
                requestPayerName: true,
                requestPayerEmail: true
            });

            pr.canMakePayment().then((result) => {
                if(result){
                    setPaymentRequest(pr);
                    setIsWalletAvailable(true);
                    setWalletType(
                        result.googlePay
                            ? googlePayPaymentButton
                            : result.applePay
                                ? applePayPaymentButton
                                : undefined
                    );
                }
            }).then(() => {
                setIsWalletCheckingFinished(true);
            });
        }

        checkWalletAvailability();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stripe, isWalletCheckingFinished])

    useEffect( () => {
        if (!stripe) {
            return;
        }

        if (!isWalletCheckingFinished) {
            return;
        }

        window.parent.postMessage({ready: true, account: buttonsComponentPage.providerAccount}, "*");

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stripe, isWalletCheckingFinished])

    const onWalletPayClicked = (walletType) => {
        paymentRequestRef.current!.show();
        sendIntentRequest(walletType);
    };

    const sendIntentRequest = (walletType) => {
        window.parent.postMessage(
            {
                isRequest: true,
                paymentApi: paymentIntentApi,
                paymentButton: walletType,
                context: context
            },
            '*'
        );
    };

    const sendWalletReadyRequest = (isWalletAvailable: boolean) => {
        window.postMessage(
            {
                isWalletAvailable: isWalletAvailable
            }, "*"
        )
    }

    const walletMessageHandler = async function (
        ev: MessageEvent<PaymentRequestData | PaymentRequestUpdate | PaymentRequestFinalize | IntentChallenged | MsisdnUpdate | PaymentCompleted>
    ) {
        if (isPaymentRequestData(ev)) {
            applyStyle();
            initializePaymentRequestHandler(ev.data);
            return;
        }

        if (isPaymentRequestUpdate(ev)) {
            updatePaymentRequestHandler(ev.data);
            return;
        }

        if (isPaymentRequestFinalize(ev)) {
            finalizeIntent(ev.data.id, ev.data.secret);
            return;
        }
    };

    const initializePaymentRequestHandler = (paymentParams: PaymentRequestData) => {
        if (!stripe || !paymentParams || !paymentRequestRef.current) {
            sendWalletReadyRequest(false);
            return;
        }

        if (!isWalletAvailableRef.current) {
            sendWalletReadyRequest(false);
            return;
        }

        i18n.changeLanguage(paymentParams.locale);
        setContext(paymentParams.context);

        paymentRequestRef.current.on('paymentmethod', async (ev) => {
            const { paymentIntent, error: confirmError } = await stripe.confirmCardPayment(
                secretRef.current!,
                { payment_method: ev.paymentMethod.id },
                { handleActions: false }
            );

            if (confirmError) {
                // Report to the browser that the payment failed, prompting it to
                // re-show the payment interface, or show an error message and close
                // the payment interface.
                ev.complete('fail');
                window.parent.postMessage(
                    {
                        finalize: true,
                        success: false,
                        errorMessage: 'an error occurred while processing the payment'
                    },
                    '*'
                );
            } else {
                // Report to the browser that the confirmation was successful, prompting
                // it to close the browser payment method collection interface.
                ev.complete('success');
                // Check if the PaymentIntent requires any actions and if so let Stripe.js
                // handle the flow. If using an API version older than "2019-02-11"
                // instead check for: `paymentIntent.status === "requires_source_action"`.
                if (paymentIntent.status === 'requires_action') {
                    // Let Stripe.js handle the rest of the payment flow.
                    const { error } = await stripe.confirmCardPayment(secretRef.current!);

                    if (error) {
                        window.parent.postMessage(
                            {
                                finalize: true,
                                success: false,
                                errorMessage: 'an error occurred while processing the payment'
                            },
                            '*'
                        );
                    } else {
                        window.parent.postMessage({ finalize: true, success: true }, '*');
                    }
                } else {
                    window.parent.postMessage({ finalize: true, success: true }, '*');
                }
            }
        });

        sendWalletReadyRequest(true);
    };

    const updatePaymentRequestHandler = (ev: PaymentRequestUpdate) => {
        i18n.changeLanguage(ev.locale);
        setContext(ev.context);

        if (!paymentRequestRef.current) {
            return;
        }

        paymentRequestRef.current.update({
            total: {
                amount: ev.amount,
                label: ev.description
            },
            currency: ev.currency
        });
    };

    const finalizeIntent = (intentId, secret) => {
        if (!stripe || !paymentRequestRef.current || !secret) {
            console.log('could not finalize intent to then set secret')
            return;
        }

        setSecret(secret);
    };

    const applyStyle = () => {
        let defaultBrandStyle: CSSProperties = {};

        if (buttonsComponentPage.presentationSettings.branding.button_color !== '') {
            defaultBrandStyle.backgroundColor = buttonsComponentPage.presentationSettings.branding.button_color;
            setStyle(defaultBrandStyle);
        }
    };

    return (
        <div>
            {isWalletAvailable && (
                <div>
                    <Button style={{...style}} onClick={() => onWalletPayClicked(walletType)}>
                        <img src={`/assets/${walletType}.svg`} alt="Wallet Logo" />
                    </Button>
                </div>
            )}
        </div>
    );
}