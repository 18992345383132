import { createTheme, adaptV4Theme } from '@mui/material';

import colors from './colors';
import shadows from './shadows';
import typography from './typography';
import { heron, heronBold, robotoRegular, robotoMedium, robotoBold } from './fonts';

const theme = createTheme(adaptV4Theme({
  typography,
  palette: {
    primary: { main: colors.blue1, contrastText: colors.white },
    secondary: { main: colors.blue5, contrastText: colors.white },
    error: { main: colors.error, contrastText: colors.white },
    success: { main: colors.success, contrastText: colors.white },
    text: {
      primary: colors.tintBlue100,
      secondary: colors.tintBlue60,
      disabled: colors.tintBlue60
    },
    action: {
      active: colors.tintBlue60,
      disabled: colors.tintBlue30,
      hover: colors.grey1,
      selected: colors.palegrey1,
    },
    divider: colors.grey2,
  },
  shadows,
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [
          heron, heronBold, robotoRegular, robotoMedium, robotoBold
        ],
        // to avoid bold scrolls on Win
        '*::-webkit-scrollbar': {
          width: 4
        },
        '*::-webkit-scrollbar-thumb': {
          background: colors.grey5
        },
        '*::-webkit-scrollbar-track': {
          boxShadow: 'inset 1px 1px 2px 0 rgba(0, 0, 0, 0.1)',
          backgroundColor: colors.grey1
        }
      },
    },
    MuiButton: {
      root: {
        borderRadius: "8px",
        height: '48px',
        textTransform: 'none'
      }
    }
  },
}));

export default theme
