import * as React from 'react'
import Typography from "@mui/material/Typography"

type Props = {
    children: React.ReactNode;
}

type State = {
    hasError: boolean
}

export default class ErrorBoundary extends React.Component< Props, State > {
    constructor(props: Props ) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch(error: any, errorInfo: any) {
        this.setState({hasError: true});
        console.log( 'error', error )
    }

    render() {
        if ( this.state.hasError ) {
            return (
                <div>
                    <Typography variant={'body1'}>
                        Oops! Something went really wrong
                    </Typography>
                </div>
            );
        }

        return <>{ this.props.children }</>;
    }
}
