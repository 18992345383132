import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import '../styles/Index.css'
import { useSearchParams } from "react-router-dom";
import formatCurrency from "src/domain/CurrencyFormatter";
import { Trans, useTranslation } from 'react-i18next';

export default function MsisdnForm() {
    const { t, i18n } = useTranslation();
    const [searchParams] = useSearchParams()
    const [isLoading, setIsLoading] = useState<boolean>()
    const [locale, setLocale] = useState<string>()
    const [amount, setAmount] = useState<number>()
    const [currency, setCurrency] = useState<string>()
    const [description, setDescription] = useState<string>()
    const [additionalInfo, setAdditionalInfo] = useState<string>()
    const [isErrorNumber, setIsErrorNumber] = useState<boolean>();
    const [isError, setIsError] = useState<boolean>();

    const isMsisdnValid = (msisdn: string) => {
        msisdn = msisdn.trim();
        const regExp = /[a-zA-Z]/g;

        if(regExp.test(msisdn)){
            return false;
        }

        return msisdn !== "" && msisdn.length < 19
    }

    const isLocaleInvalid = (localeUrlParam: string | null) => !localeUrlParam;
    const isAmountInvalid = (amountUrlParam: string | null) => !amountUrlParam;
    const isCurrencyInvalid = (currencyUrlParam: string | null) => !currencyUrlParam;
    const isDescriptionInvalid = (descriptionUrlParam: string | null) => !descriptionUrlParam;
    const isAdditionalInfoInvalid = (additionalInfoUrlParam: string | null) => !additionalInfoUrlParam;

    const onComplete = () => {
        let msisdn = document.getElementById("msisdn") as HTMLInputElement;
        if(msisdn){
            if (isMsisdnValid(msisdn.value)){
                window.opener.postMessage({isUpdateZahlEinfachRequest: true, msisdn: msisdn.value, failureUrl: window.location.href + "&success=false"}, document.referrer);
                setIsLoading(true);
                setInterval(function () {window.close()}, 20000);
            }else{
                setIsErrorNumber(true);
                setIsError(false);
            }
        }
    }

    const loadFavicon = () => {
        const link = document.createElement('link');
        link.id = 'favicon';
        link.rel = 'shortcut icon';
        document.head.appendChild(link);
    
        (link as HTMLLinkElement).href = require(`../assets/freenet-favicon.ico`);
    }

    useEffect(() => {
        const queryString = searchParams.get("q");

        if(!queryString || queryString === null || queryString === ""){
            return;
        }

        const error = searchParams.get("success")
        if(error === "false"){
            setIsErrorNumber(false);
            setIsError(true);
        }

        let qParams = atob(queryString);
        const urlParams = new URLSearchParams(qParams);
        let localeUrlParam = urlParams.get("locale");
        let amountUrlParam = urlParams.get("amount");
        let currencyUrlParam = urlParams.get("currency");
        let descriptionUrlParam = urlParams.get("description");
        let additionalInfoUrlParam = urlParams.get("additional_info");
        loadFavicon();
        
        if(isLocaleInvalid(localeUrlParam)){
            return;
        }

        if(isAmountInvalid(amountUrlParam)){
            return;
        }

        if(isAmountInvalid(amountUrlParam)){
            return;
        }
        
        if(isCurrencyInvalid(currencyUrlParam)){
            return;
        }

        if(isDescriptionInvalid(descriptionUrlParam)){
            return;
        }

        setLocale(localeUrlParam!);
        setAmount(Number(amountUrlParam));
        setCurrency(currencyUrlParam!);
        setDescription(decodeURIComponent(descriptionUrlParam!));

        if(!isAdditionalInfoInvalid(additionalInfoUrlParam)){
            setAdditionalInfo(decodeURIComponent(additionalInfoUrlParam!));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[locale, amount, currency, description, additionalInfo])
    
    const classError = isError || isErrorNumber ? "error" : "";

    return <div>
        {locale && amount && currency &&(
        <Grid container justifyContent="center">
            <Grid item xs={6}>
                <Grid container className="top-logo">
                    <Grid item xs={6}>
                        <img src={require("../assets/ze_logo.png")} alt="Zahl einfach"/>
                    </Grid>
                    
                    <Grid item xs={6}  className="freenet-logo" textAlign={"end"}>
                        <img src={require("../assets/freenet-logo.svg").default} alt="Freenet"/>
                    </Grid>
                </Grid>
            </Grid>
            
            <Grid item className="line"></Grid>

            <Grid container justifyContent="center">
                <Grid item xs={6}>
                    <div className="summary">
                        <span className="plan">{description}</span>
                        <span className="price">{formatCurrency(locale, currency, amount)}</span>
                    </div>
                </Grid>
            </Grid>

            <Grid container justifyContent="center" className="form-details">
                <Grid item xs={6}>
                        <p>{additionalInfo}</p>
                
                        <div className={"input-msisdn " + (isErrorNumber ? classError : "")}>
                            <span><label htmlFor="msisdn">{t("mobileNumberRequestLable")}</label></span>
                            <input type="tel" id="msisdn" maxLength={18}></input>
                        </div>
                        {isErrorNumber && (
                            <span className="note error">{t("mobileNumberError")}</span>
                        )}
                        {isError && (
                            <span className="note error">{t("errorMessage")}</span>
                        )} 
                </Grid>
            </Grid>

            <Grid container justifyContent="center">
                <Grid item xs={6}>
                    <span className="note">
                        <Trans
                            i18nKey="individualPurchaseNote"
                            shouldUnescape={true}
                            values={{ amount: formatCurrency(locale, currency, amount)}}/>
                    </span>
                </Grid>
            </Grid>

            <Grid className="checkout-buttons" container justifyContent="center">
                <Grid item xs={6}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <button className="freenet-bg-color" onClick={() => onComplete()}>
                                {t("continue")}
                            </button>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <button className="second-color" onClick={() => window.close()}>
                                {t("back")}
                            </button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container justifyContent="center" className="footer-links">
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item md>
                            <p><a target="_blank" rel="noreferrer" href="https://www.zahleinfachperhandyrechnung.de/Impressum">Impressum</a></p>
                            <p><a target="_blank" rel="noreferrer" href="https://www.zahleinfachperhandyrechnung.de/Datenschutz">Datenschutz</a></p>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {isLoading && (
                <div className="loading"></div>
            )}
        </Grid>
        )}
    </div>
}
