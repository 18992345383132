import * as React from 'react'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import { Instrument } from "src/domain/HostedSession"
import { useTranslation } from 'react-i18next';

type ExistingInstrumentProps = {
    instrument: Instrument | undefined;
    requestCardChange(state: boolean): void;
}

const ExistingInstrument: React.FC<ExistingInstrumentProps> = (props: ExistingInstrumentProps) => {
    const { t, i18n } = useTranslation();
    const {instrument, requestCardChange} = props;

    const getBrandCardImagePath = (brand: string | undefined) : string => {
        if (brand === undefined) {
            return "";
        }
        switch (brand) {
            case "visa":
                return "/assets/visa.png";
            case "mastercard":
                return "/assets/mastercard.png";
            case "diners":
                return "/assets/diners.png";
            case "dicover":
                return "/assets/discover.png";
            case "amex":
                return "/assets/amex.png";
            case "cartes_bancaires":
                return "/assets/cartes_bancaires.png";
            case "jcb":
                return "/assets/jcb.png";
            case "unionpay":
                return "/assets/unionpay.png";
        }
        return "/assets/generic.png"
    } 

    const changeCard = (e) => {
        requestCardChange(false);
    }

    return (
        <div>
            <Card variant="outlined">
                <Grid container style={{margin: "12px"}}>
                    <Grid item xs={3}>
                        <div>{t("payWith")}
                        </div>
                    </Grid>
                    <Grid item xs={1}>
                    </Grid>
                    <Grid item xs={1}>
                        <img alt='instrument' style={{width: "30px", borderRadius: "3px", border: "solid 1px lightgrey"}} src={getBrandCardImagePath(instrument?.brand)} />
                    </Grid>
                    <Grid item xs={2} style={{textAlign: "left"}}>
                        •••• {instrument?.last4}
                    </Grid>
                    <Grid item xs={4} style={{textAlign: "right"}}>
                        <span className="clickable-text" onClick={changeCard}>{t("change")}</span>
                    </Grid>
                </Grid>
                {/* <div style={{margin: "12px", display: "flex"}}>
                    <span style={{width: "30%", margin: 'auto 0'}}>
                        Pay with
                    </span>
                    <div className="image-wrapper">
                    </div>
                    <div style={{margin: "auto 12px"}}>
                        •••• {instrument.last4}
                    </div>
                    <div style={{width: "*"}}>
                        <span style={{textAlign: "right"}}>Change</span>
                    </div>
                </div> */}
            </Card>
        </div>
    )
}

export { ExistingInstrument }
