import React, { useState } from "react";
import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { Button, CircularProgress, Grid } from "@mui/material";
import formatCurrency from "src/domain/CurrencyFormatter";
import { t } from "i18next";
import { IntentPage } from "src/features/enterprise/intents/types/IntentPage";
import { NotificationPaymentStatus } from "src/features/enterprise/intents/types/IntentsList";
import { Layout, PaymentWalletOption } from "@stripe/stripe-js";

export default function InvoiceForm(page: IntentPage) {
    const stripe = useStripe();
    const elements = useElements();
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [isPaying, setIsPaying] = useState<boolean>(false);
    const [paymentButtons, setPaymentButtons] = useState(false);

    const handleSubmit = () => {
        if (isPaying) {
            return;
        }

        setIsPaying(true);

        let redirectUrl = window.location.protocol + "//" + window.location.host + `/invoices?succeeded=${encodeURIComponent(page.intent.description)}&q=` + encodeURIComponent(page.returnUrl);

        confirmStripePayment(redirectUrl);
    };

    const confirmStripePayment = (redirectUrl) => {
        if (!stripe || !elements) {
            return
        }

        stripe.confirmPayment({
            elements,
            redirect: "if_required",
            confirmParams: {
                return_url: redirectUrl,
            },
        }).then((value) => {
            setIsPaying(false);
            if(value.paymentIntent?.status === "requires_action" && value.paymentIntent.next_action != null){
                
                page.successCallBack(encodeURIComponent(page.intent.description), NotificationPaymentStatus.Processing);
                return;
            }

            if(value.paymentIntent?.status === "succeeded"){
                page.successCallBack(encodeURIComponent(page.intent.description), NotificationPaymentStatus.Paid);
                return;
            }

            if(value.paymentIntent?.status === "processing"){
                page.successCallBack(encodeURIComponent(page.intent.description), NotificationPaymentStatus.Processing);
                return;
            }

            // only reached if an error occurs, otherwise the success redirect page is called
            if (value.error?.type === "card_error" || value.error?.type === "validation_error") {
                setErrorMessage(value.error?.message ?? '');
            } else {
                const errorMsg = t("errorMessage");
                setErrorMessage(errorMsg);
            }
        }).catch((error) => { setIsPaying(false);});
    }

    const options = {
        layout: {
            type: ('tabs' as Layout),
            defaultCollapsed: false,
        },
        wallets: {
            applePay: ('never' as PaymentWalletOption),
            googlePay: ('never' as PaymentWalletOption)
        },
        paymentMethodOrder: page.instrumentTypes
    };

    return (
        <div style={{marginTop:"8px"}}>
            <Grid container spacing={1} direction="row" justifyContent="center">
                <Grid item xs={12} sm={6} md={4} style={{position: "relative"}}>
                    <div style={{maxHeight: "30px", display: "flex", alignItems: "center", marginBottom: "30px"}}>
                        <div id="backButton" style={{minHeight: "30px", display: "flex", alignItems: "center", float:"left", marginRight: "12px", cursor: "pointer"}} onClick={() => page.pageBack()}>
                            <svg width="12" height="12" viewBox="0 0 16 16"><path d="M3.417 7H15a1 1 0 0 1 0 2H3.417l4.591 4.591a1 1 0 0 1-1.415 1.416l-6.3-6.3a1 1 0 0 1 0-1.414l6.3-6.3A1 1 0 0 1 8.008 2.41z" fill-rule="evenodd"></path></svg>
                        </div>
                        <div id="logo" style={{float: "left"}}>
                            <img className="branding-logo" width="auto" height="auto" src={page.presentationSettings?.assets.logo} alt="brandingLogo"></img>
                        </div>
                        <div id="backLabel" style={{float: "left", fontWeight:500, fontSize: "14px"}}>
                            <span>{t("back")}</span>
                        </div>
                    </div>
                    <div style={{marginBottom: "15px"}}>
                        
                        <Grid container spacing={1} className="xs-centered" >
                            <Grid item xs={12}>
                                <span>{t("invoiceNumber")}: {page.intent?.description}</span>
                            </Grid>
                            <Grid item xs={12}>
                                <span style={{fontWeight: 500, fontSize: "36px", letterSpacing: "-.03rem"}}>{formatCurrency(page.locale, page.intent.currency, page.intent.amount)}</span>
                            </Grid>
                        </Grid>
                    </div>
                    <div className="lg-footer" style={{ width: "100%", textAlign: "center", bottom: "0px", position: "absolute"}}>
                        <a className="dottedLink" href="https://stripe.com/legal/end-users">{t("terms")}</a>
                        <a className="dottedLink" href="https://stripe.com/privacy">{t("privacy")}</a>
                    </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <PaymentElement options={options} id="payment-element"/>

                    <div hidden={errorMessage.length === 0} style={{ marginTop: '15px', color: '#dc2727'}}>{errorMessage}</div>
                    <div style={{ marginTop: "20px" }}>
                        <Button
                            onClick={handleSubmit}
                            color={"primary"}
                            fullWidth={true}
                            variant="contained"
                            style={{ opacity: isPaying ? 0.2 : 1, height: "42px", textTransform: 'none', color: 'white', backgroundColor: page.presentationSettings?.branding.button_color}}
                        >
                            <span hidden={isPaying}>{t("pay")}</span>
                            <div hidden={!isPaying}>
                                <CircularProgress size="1.75rem" style={{'color': 'white'}}/>
                            </div>
                        </Button>
                    </div>
                </Grid>
            </Grid>
            <div className="xs-footer" style={{textAlign: "center", bottom: 0, marginTop: "20px"}}>
                <a className="dottedLink" href="https://stripe.com/legal/end-users">{t("terms")}</a>
                <a className="dottedLink" href="https://stripe.com/privacy">{t("privacy")}</a>
            </div>
        </div>  
    );
}

