const currencyDecimal = {
    //Zero-decimal currencies
    "BIF": 0,
    "CLP": 0,
    "DJF": 0,
    "GNF": 0,
    "JPY": 0,
    "KMF": 0,
    "KRW": 0,
    "MGA": 0,
    "PYG": 0,
    "RWF": 0,
    "UGX": 0,
    "VND": 0,
    "VUV": 0,
    "XAF": 0,
    "XOF": 0,
    "XPF": 0,
    //Three-decimal currencies
    "BHD": 3,
    "JOD": 3,
    "KWD": 3,
    "OMR": 3,
    "TND": 3
}

export default function formatCurrency(locale: string, currency: string, amount: number): string {
    if (locale === '' || locale === undefined || locale === null ||  locale === 'auto') {
        locale = 'en';
    }

    const cur: string = currency.toUpperCase();
    const decimal: number = currencyDecimal[currency.toUpperCase()] ?? 2;
    const intlFormat = new Intl.NumberFormat(locale, { style: 'currency', currency: cur,  });

    if (decimal === 0) {
        return intlFormat.format(amount);
    }
    return intlFormat.format((amount / 100));
}