import { useLayoutEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import config from "src/config";
import { CompleteRequest } from "../workers/types";
import { dispatch } from "src/workers/common-worker";
import { Grid } from "@mui/material";
import '../styles/Index.css'
import FailContent from "./FailContent";

export default function Fail() {
    const [isCompleted, setIsCompleted] = useState<boolean>()
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const paymentsServiceUrl: string =  config.apis.paymentServiceUrl;
    const tokenMediatorUrl: string =  config.apis.tokenMediatorUrl;
    const worker: Worker = useMemo(
        () => new Worker(new URL("../workers/fail-worker.ts", import.meta.url)),
        []
    );
 
    useLayoutEffect(() => {
        loadFavicon();

        if (Worker) {
            const intentId = searchParams.get("intent_id") || "";
            const tenantId = searchParams.get("t_id") || "";
            const testMode = searchParams.get("test_mode") || "";

            const request: CompleteRequest = {
                tokenMediatorUrl: tokenMediatorUrl,
                paymentsServiceUrl: paymentsServiceUrl,
                intentId: intentId,
                testMode: testMode === "true",
                tenantId: tenantId
            };

            dispatch<CompleteRequest, any>(worker, request)
                .then(() => {
                    setIsCompleted(true);
                    if(window.opener){
                        window.opener.postMessage({finalize: true, success: false}, "*");
                        window.close();
                    }
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const loadFavicon = () => {
        const link = document.createElement('link');
        link.id = 'favicon';
        link.rel = 'shortcut icon';
        document.head.appendChild(link);

        (link as HTMLLinkElement).href = require(`../assets/freenet-favicon.ico`);
    }
    
    return (
        <div>
            {isCompleted && (
                <div><FailContent /></div>
            )}

            {!isCompleted &&(
                <div className="loading"></div>
            )}
        </div>
    )
}