import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import '../styles/Index.css'

export default function FailContent() {
    const { t } = useTranslation();

    return (
        <div>
            <Grid container justifyContent="center" alignItems="center" style={{height: "100%", position:"absolute"}}>
                <Grid item xs={6} textAlign={"center"}>
                    <img width={64} src={require('../assets/fail.png')} alt=""/>
                    <h2 className="primary-font-color">{t("unsuccessPurchase")}</h2>
                    <p className="primary-font-color">{t("unsuccessPurchaseMessage")}</p>
                </Grid>
            </Grid>
        </div>
    )
}