import { useEffect } from "react";

const send = (status, clientRef, hostedSessionId) => {
    var postMessage = '{"Status":"' + status + '","HostedSessionId":"' + hostedSessionId + '"';
    if (clientRef != null) { 
        postMessage = postMessage + ',"ClientReference":"' + clientRef + '"';
    }
    postMessage = postMessage + '}';

    if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(postMessage);
    } else {
      if (window && window.parent) {
        window.parent.postMessage(postMessage);
      }
    }
}

const Loader = () => (
    <div class="lds-dual-ring"></div>
);

export default function Confirmation() {
    useEffect(() => {
        // Check to see if this is a redirect back from Checkout
        const query = new URLSearchParams(window.location.search);
        
        if (query.get("status")) {
            send(query.get("status"), query.get("clientRef"), query.get("hostedSessionId"));
        }
    }, []);

    return Loader();
}