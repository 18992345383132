export class PresentationSettings {
    assets : Assets;
    branding : Branding;
    display_name : string;
    terms_of_service_url: string;
    privacy_policy_url:string;
    google_pay: GooglePaySettings;

    constructor() {
        this.assets = new Assets();
        this.branding = new Branding();
        this.display_name = "";
        this.terms_of_service_url = "";
        this.privacy_policy_url = "";
        this.google_pay = new GooglePaySettings();
    }
}

export class Assets {
    favicon: string;
    logo: string;

    constructor() {
        this.favicon = "";
        this.logo = "";
    }
}

export class Branding {
    button_color: string;
    background_color: string;

    constructor() {
        this.button_color = "";
        this.background_color = "";
    }

}

export class GooglePaySettings {
    merchant_name: string;
    merchant_id: string;

    constructor(name: string = "", id: string = "") {
        this.merchant_name = name;
        this.merchant_id = id;
    }
}
