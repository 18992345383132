import { Shadows } from '@mui/material/styles';

const shadows = Array(25).fill('none') as Shadows;

shadows[1] = '0 0 1px 0 #00000014, 0 1px 5px 0 #0000000c;';
shadows[2] = '0 0 2px 0 #00000014, 0 2px 2px 0 #0000000c;';
shadows[3] = '0 0 3px 0 #00000014, 0 3px 5px 0 #0000000c;';
shadows[4] = '0 1px 10px 0 #00000014, 0 4px 5px 0 #0000000c;';
shadows[6] = '0 1px 18px 0 #00000014, 0 6px 10px 0 #0000000c;';
shadows[8] = '0 3px 14px 0 #00000014, 0 8px 10px 0 #0000000c;';
shadows[9] = '0 3px 16px 0 #00000014, 0 9px 12px 0 #0000000c;';
shadows[12] = '0 5px 22px 0 #00000014, 0 12px 17px 0 #0000000c;';
shadows[16] = '0 6px 30px 0 #00000014, 0 16px 24px 0 #0000000c;';
shadows[20] = '0 20px 60px 0 #00000014, 0 20px 40px 0 #0000000c;';

export default shadows;
