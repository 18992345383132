import {Elements, PaymentElement, useElements, useStripe} from "@stripe/react-stripe-js";
import { InstrumentHostedSession } from "src/domain/HostedSession";
import { Stripe, StripeElementsOptions } from "@stripe/stripe-js";
import { useEffect, useLayoutEffect, useState } from "react";
import { Button, CircularProgress } from "@mui/material";
import { t } from "i18next";

interface ICheckoutStripeProps {
    options : StripeElementsOptions; 
    stripePromise: Promise<Stripe | null> | null;
    hostedSession : InstrumentHostedSession;
}

export default function SetupFormStripe({stripeFormData}: {stripeFormData: ICheckoutStripeProps}) {
    return (
        <Elements options={stripeFormData.options} stripe={stripeFormData.stripePromise}>
            <StripeForm {...stripeFormData.hostedSession}></StripeForm>
        </Elements>
    );
}

function StripeForm(instrumentHostedSession : InstrumentHostedSession) {
    const stripe = useStripe();
    const elements = useElements();
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [isSubmiting, setIsSubmiting] = useState<boolean>(false);

    useLayoutEffect( () => {
        if (!stripe) {
            return;
        }
    }, [stripe])
    
    const handleSubmit = () => {
        setIsSubmiting(true);
    };

    useEffect(() => {
        if (!isSubmiting) {
                return;
        }

        confirmStripeSetup();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSubmiting])

    const confirmStripeSetup = () => {
        if (!stripe || !elements) {
            return
        }

        stripe.confirmSetup({
            elements,
            confirmParams: {
                return_url: instrumentHostedSession.success_url,
            },
        }).then((value) => {
            setIsSubmiting(false);
            // only reached if an error occurs, otherwise the success redirect page is called
            if (value.error.type === "card_error" || value.error.type === "validation_error") {
                setErrorMessage(value.error?.message ?? '');
            } else {
                const errorMsg = t("errorMessage");
                setErrorMessage(errorMsg);
            }
        }).catch((error) => { setIsSubmiting(false);});
    }
    
    return (
      <>
        <PaymentElement id="payment-element"/>
        <div hidden={errorMessage.length === 0} style={{ marginTop: '15px', color: '#dc2727'}}>{errorMessage}</div>
        <div style={{ marginTop: "20px" }}>
            <Button
                onClick={handleSubmit}
                color={"primary"}
                fullWidth={true}
                variant="contained"
                style={{ opacity: isSubmiting ? 0.2 : 1, backgroundColor: instrumentHostedSession.presentation_settings.branding.button_color}}
            >
                <span hidden={isSubmiting}>{t("submit")}</span>
                {<div hidden={!isSubmiting}>
                    <CircularProgress size="1.75rem" style={{'color': 'white'}}/>
                </div>}
            </Button>
        </div>
      </>
    );
}
