export type ThemeRequest = {
    tokenMediatorUrl: string,
    paymentsServiceUrl: string,
    hostedSessionId: string,
    configId: string
}

export class ThemeSettings {
    assets : Assets;
    branding : Branding;
    displayName : string;

    constructor() {
        this.assets = new Assets();
        this.branding = new Branding();
        this.displayName = "";
    }
}

export class Assets {
    favicon: string;
    logo: string;
    loadingSpinner: string;

    constructor() {
        this.favicon = "";
        this.logo = "";
        this.loadingSpinner = "";
    }
}

export class Branding {
    buttonColor: string;
    backgroundColor: string;

    constructor() {
        this.buttonColor = "";
        this.backgroundColor = "";
    }

}
