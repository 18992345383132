import { Typography } from '@mui/material/styles/createTypography';

export default {
  fontFamily: 'Roboto, HeronSansCond, Helvetica, Arial',

  h1: {
    fontFamily: 'Inter, HeronSansCond, Roboto, Helvetica',
    fontSize: '96px',
    fontWeight: 700,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1,
    letterSpacing: 'normal'
  },

  h2: {
    fontFamily: 'Inter, HeronSansCond, Roboto, Helvetica',
    fontSize: '60px',
    fontWeight: 700,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1,
    letterSpacing: '1px'
  },

  h3: {
    fontFamily: 'Inter, HeronSansCond, Roboto, Helvetica',
    fontSize: '44px',
    fontWeight: 700,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.05,
    letterSpacing: '2px'
  },

  h4: {
    fontFamily: 'Inter, HeronSansCond, Roboto, Helvetica',
    fontSize: '33px',
    fontWeight: 700,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 0.91,
    letterSpacing: '1.5px'
  },

  h5: {
    fontFamily: 'Roboto, Helvetica',
    fontSize: '24px',
    fontWeight: 400,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal'
  },

  h6: {
    fontFamily: 'Roboto, Helvetica',
    fontSize: '20px',
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.6,
    letterSpacing: 'normal'
  },

  subtitle1:  {
    fontFamily: 'Roboto, Helvetica',
    fontSize: '16px',
    fontWeight: 700,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal'
  },

  subtitle2: {
    fontFamily: 'Roboto, Helvetica',
    fontSize: '14px',
    fontWeight: 700,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.29,
    letterSpacing: '0.5px'
  },

  body1: {
    fontFamily: 'Roboto, Helvetica',
    fontSize: '16px',
    fontWeight: 400,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal'
  },

  body2: {
    fontFamily: 'Roboto, Helvetica',
    fontSize: '14px',
    fontWeight: 400,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.43,
    letterSpacing: 'normal'
  },

  caption: {
    fontFamily: 'Roboto, Helvetica',
    fontSize: '12px',
    fontWeight: 400,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '0.3px'
  },

  overline: {
    fontFamily: 'Roboto, Helvetica',
    fontSize: '14px',
    fontWeight: 700,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '1.5px'
  },

  button: {
    fontFamily: 'Inter, Roboto, Helvetica',
    fontSize: '16px',
    fontWeight: 700,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '1.5px',
    textAlign: 'center'
  }
} as Typography
