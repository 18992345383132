import { useEffect, useLayoutEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import i18n from "i18next"
import { Button, CircularProgress, OutlinedInput } from "@mui/material"
import { useNavigate, useSearchParams } from "react-router-dom"
import { dispatch } from "src/workers/common-worker"
import { InvoicesLoginRequest } from "../workers/types";
import config from "src/config";

const paymentsServiceUrl: string =  config.apis.paymentServiceUrl
const tokenMediatorUrl: string =  config.apis.tokenMediatorUrl

let tenant: string | null = null

export default function Login() {
    const {t} = useTranslation()
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()
    const [accountNumber, setAccountNumber] = useState<string>("")
    const [invoiceNumber, setInvoiceNumber] = useState<string>("")
    const [logingIn, setLogingIn] = useState<boolean>(false)
    const [loggingError, setLoggingError] = useState<string>("")

    const worker: Worker = useMemo(
        () => new Worker(new URL("../workers/invoices-login-worker.ts", import.meta.url)),
        []
    );
    
    useLayoutEffect(() => {
        async function setLocale() {
            let locale = getLocale();
            await i18n.changeLanguage(locale.replace("-", "_"));
        }

        document.body.style.backgroundColor = "#f5f5f5";
        setLocale();
    }, [])

    useEffect(() => {
        tenant = searchParams.get("t")
    }, [searchParams])

    const login = () => {
        setLogingIn(true)

        const request: InvoicesLoginRequest = {
            tokenMediatorUrl: tokenMediatorUrl,
            paymentsServiceUrl: paymentsServiceUrl,
            customerId: accountNumber,
            nonce: invoiceNumber,
            tenantId: encodeURIComponent(tenant ?? "")
        };
    
        dispatch<InvoicesLoginRequest, InvoicesLoginRequest>(worker, request)
            .then(data => resolveLogin(data));
    }

    async function resolveLogin(data) {
        if (data.status !== undefined && data.status !== 200) {
            setLogingIn(false);
            setLoggingError("error");
            return;
        }
        await navigate("/invoices", {state: {invoicesList: data}})
    }

    const onAccountNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLoggingError("");
        setAccountNumber(event.target.value)
    }

    const onInvoiceNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLoggingError("");
        setInvoiceNumber(event.target.value)
    }

    return (
        <div className="login-canvas">
            <div >
                <img style={{maxHeight: "30px"}} className="center" src="assets/logo1global.svg"  alt="1Global Logo"/>
            </div>
            <div className="row" style={{fontWeight: 700, marginTop:"20px"}}>
                <span>{t('accountNumber')}</span>
            </div>
            <div>
                <OutlinedInput className="wide" size="small" onChange={onAccountNumberChange}></OutlinedInput>
            </div>
            <div className="row" style={{fontWeight: 700, marginTop:"20px"}}>
                <span>{t('latestInvoiceNumber')}</span>
            </div>
            <div>
                <OutlinedInput className="wide" size="small" onChange={onInvoiceNumberChange}></OutlinedInput>
            </div>
            <div>
                <span className={`login-error-message ${loggingError !== "" ? 'show' : ''}`}>
                    {t('loginErrorMessage')}
                </span>
            </div>
            <div className="wide-row">
                <button 
                    disabled={tenant === null || accountNumber === "" || invoiceNumber === ""}
                    className={"defaultButton wide " + (accountNumber === "" || invoiceNumber === "" || tenant === null ? "disabled-button" : "")}
                    style={{background: "#1e2c51"}}
                    onClick={login}>
                        {!logingIn && <span>{t('login')}</span>}
                        {logingIn && <div><CircularProgress size="1rem" style={{'color': 'white'}}/></div>}
                </button>
            </div>
        </div>
    )
}

function getLocale() {
    let locale = window.navigator.language;

    if(locale !== null && locale !== undefined && locale !== "") {
        if (locale === "zh") {
            locale = "zh_Hans";
        }
        if (locale === "zh-HK") {
            locale = "zh_Hant_HK";
        }
        if (locale === "nb") {
            locale = "nb_NO";
        }
    } else {
        locale = "en";
    }

    return locale;
}