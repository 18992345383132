import { ValidateNested } from 'class-validator';
import {PresentationSettings} from "src/domain/PresentationSettings";
import {Intent} from "./Intent";
import { Instrument } from 'src/features/enterprise/instruments/types/Instrument';
import { Nullable } from 'src/workers/types';

export enum NotificationPaymentStatus{
    Pending,
    Processing,
    Paid
}

export class IntentsList {

    @ValidateNested({ each: true }) 
    intents: Intent[];
    presentationSettings: PresentationSettings;
    tenantId: string;
    customerId: string;
    locale: string;
    directDebitInstrument: Nullable<Instrument>;
    instrumentTypes: string[];
    
    constructor() {
        this.intents = [];
        this.presentationSettings = new PresentationSettings();
        this.tenantId = "";
        this.customerId = "";
        this.locale = "";
        this.directDebitInstrument = null;
        this.instrumentTypes = [];
    }
}