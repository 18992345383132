export type Nullable<T> = T | null;

export enum InitRequestType{
    Payment,
    Setup
}

export type InitRequest = {
    tokenMediatorUrl: string,
    paymentsServiceUrl: string,
    hsID: string
    configId: string
    type: InitRequestType
}

export type InitInvoiceRequest = {
    tokenMediatorUrl: string,
    paymentsServiceUrl: string,
    configId: string
}

export type InitResponse = {
    // TODO revisit
    page: any
}

export type RedirectRequest = {
    tokenMediatorUrl: string,
    paymentsServiceUrl: string,
    hsID: string,
    clientSecret: string
    testMode: boolean
}

export type CompleteRequest = {
    tokenMediatorUrl: string,
    paymentsServiceUrl: string,
    hsID: string,
    clientSecret: string
    testMode: boolean
    tenantId: string
}

export type RedirectResponse = {
}