import { IsNotEmpty, IsString, IsNumber, Min } from 'class-validator';

export class Intent {

    @IsNotEmpty()
    @IsString()
    id: string;

    @IsString()
    description: string;
    
    //@IsNotEmpty()
    @IsString()
    client_secret: string;

    @IsNotEmpty()
    @IsNumber()
    @Min(1)
    amount: number;

    @IsNotEmpty()
    @IsString()
    currency: string;
    created_stamp: string

    brand_id: string;

    constructor() {
        this.id = ""
        this.client_secret = "";
        this.amount = 0;
        this.currency = "";
        this.description = "";
        this.created_stamp = "";
        this.brand_id = "";
    }
}