import { validate, ValidationError, ValidateNested, ArrayNotEmpty, IsNotEmpty, IsString, IsNumber, Min, Max } from 'class-validator';
import { StripeElementLocale } from "@stripe/stripe-js";
import {PresentationSettings} from "./PresentationSettings";

export class HostedSession {

    @IsNotEmpty()
    @IsString()
    client_secret: string;

    //@ArrayNotEmpty()
    //@ValidateNested({ each: true })
    items: ProductItem[];

    @IsNotEmpty()
    @IsNumber()
    @Min(1)
        //@Max(1000)
    amount: number;

    @IsNotEmpty()
    @IsString()
    currency: string;

    success_url: string;
    cancel_url: string;
    locale: StripeElementLocale;

    instrument: Instrument | undefined;

    presentation_settings: PresentationSettings;

    test_mode: boolean

    tenant_id: string;

    constructor() {
        this.client_secret = "";
        this.items = [];
        this.amount = 0;
        this.currency = "";
        this.success_url = "";
        this.cancel_url = "";
        this.instrument = undefined;
        this.locale = "en";
        this.presentation_settings = new PresentationSettings();
        this.test_mode = false;
        this.tenant_id = "";
    }
}

export class InstrumentHostedSession {
    @IsNotEmpty()
    @IsString()
    client_secret: string;
    locale: StripeElementLocale;
    presentation_settings: PresentationSettings;
    success_url: string;
    test_mode: boolean
    tenant_id: string;

    constructor() {
        this.client_secret = "";
        this.locale = "en";
        this.success_url = "";
        this.presentation_settings = new PresentationSettings();
        this.test_mode = false;
        this.tenant_id = "";
    }
}

export class Instrument {
    id?: string;
    brand?: string;
    last4?: string;

    constructor() {
        this.id = "";
        this.brand = "";
        this.last4 = "";
    }
}

class ProductItem {
    product_id: string;
    name: string;
    quantity: number;
    unit_price: number;
    additional_info: string;

    constructor() {
        this.product_id = "";
        this.name = "";
        this.quantity = 0;
        this.unit_price = 0;
        this.additional_info = "";
    }
}