import { PaymentButtons } from 'payments-ui-components/components/types';
import { PresentationSettings } from 'src/domain/PresentationSettings';

export type ButtonsComponentPage = {
  brandAllowedPaymentButtons: PaymentButtons[];
  allowedPaymentButtons: PaymentButtons[];
  presentationSettings: PresentationSettings;
  providerAccount: string;
  amount: number;
  currency: string;
  description: string;
  provider: string;
};

export type PaymentRequestData = {
  locale: string;
  account: string;
  currency: string;
  amount: number;
  description: string;
  additionalInfo: string;
  context: unknown;
  tenant: string;
  brand: string;
  allowedPaymentButtons?: PaymentButtons[];
};

export type PaymentRequestUpdate = {
  amount: number;
  currency: string;
  description: string;
  additionalInfo: string;
  locale: string;
  context: unknown;
};

export type PaymentRequestFinalize = {
  id: string;
  secret: string;
};

export type MsisdnUpdate = {
  msisdn: string;
  failureUrl: string;
};

export type IntentChallenged = {
  intentChallengeUrl: string;
};

export type IntentFailed = {
  redirectUrl: string;
  closeWindow: boolean;
};

export type PaymentCompleted = {
  finalize: boolean;
  success: boolean;
};

export type WalletLayoutReady = {
  isWalletAvailable: boolean;
}

export const isPaymentRequestData = function (
  request: MessageEvent<PaymentRequestData | PaymentRequestUpdate | PaymentRequestFinalize | IntentChallenged | IntentFailed | MsisdnUpdate | PaymentCompleted | WalletLayoutReady>
): request is MessageEvent<PaymentRequestData> {
  return (request as MessageEvent<PaymentRequestData>).data?.tenant !== undefined;
};

export const isPaymentRequestUpdate = function (
  request: MessageEvent<PaymentRequestData | PaymentRequestUpdate | PaymentRequestFinalize | IntentChallenged | IntentFailed | MsisdnUpdate | PaymentCompleted | WalletLayoutReady>
): request is MessageEvent<PaymentRequestUpdate> {
  return (request as MessageEvent<PaymentRequestUpdate>).data.amount !== undefined;
};

export const isPaymentRequestFinalize = function (
  request: MessageEvent<PaymentRequestData | PaymentRequestUpdate | PaymentRequestFinalize | IntentChallenged | IntentFailed | MsisdnUpdate | PaymentCompleted | WalletLayoutReady>
): request is MessageEvent<PaymentRequestFinalize> {
  return (request as MessageEvent<PaymentRequestFinalize>).data.secret !== undefined;
};

export const isIntentChallenged = function (
  request: MessageEvent<PaymentRequestData | PaymentRequestUpdate | PaymentRequestFinalize | IntentChallenged | IntentFailed | MsisdnUpdate | PaymentCompleted | WalletLayoutReady>
): request is MessageEvent<IntentChallenged> {
  return (request as MessageEvent<IntentChallenged>).data?.intentChallengeUrl !== undefined;
};

export const isIntentFailed = function (
    request: MessageEvent<PaymentRequestData | PaymentRequestUpdate | PaymentRequestFinalize | IntentChallenged | IntentFailed | MsisdnUpdate | PaymentCompleted | WalletLayoutReady>
): request is MessageEvent<IntentFailed> {
  return (request as MessageEvent<IntentFailed>).data?.redirectUrl !== undefined;
};

export const isMsisdnUpdate = function (
  request: MessageEvent<PaymentRequestData | PaymentRequestUpdate | PaymentRequestFinalize | IntentChallenged | IntentFailed | MsisdnUpdate | PaymentCompleted | WalletLayoutReady>
): request is MessageEvent<MsisdnUpdate> {
  return (request as MessageEvent<MsisdnUpdate>).data?.msisdn !== undefined;
};

export const isPaymentCompleted = function (
  request: MessageEvent<PaymentRequestData | PaymentRequestUpdate | PaymentRequestFinalize | IntentChallenged | IntentFailed | MsisdnUpdate | PaymentCompleted | WalletLayoutReady>
): request is MessageEvent<PaymentCompleted> {
  return (request as MessageEvent<PaymentCompleted>).data?.finalize;
};

export const isWalletLayoutReady = function (
    request: MessageEvent<PaymentRequestData | PaymentRequestUpdate | PaymentRequestFinalize | IntentChallenged | IntentFailed | MsisdnUpdate | PaymentCompleted | WalletLayoutReady>
): request is MessageEvent<WalletLayoutReady> {
  return (request as MessageEvent<WalletLayoutReady>).data?.isWalletAvailable !== undefined;
};
