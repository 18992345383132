type FileLoading = {
    src: string;
}

export function GetLoading(file: FileLoading){
    if(!file.src){
        return <div className="lds-dual-ring"></div>;
    }

    return (<div className="app-loading" style={{backgroundImage: `url(${file.src})`}}></div>);
}