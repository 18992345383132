import { Button, Grid } from '@mui/material';
import { CSSProperties, useEffect, useLayoutEffect, useRef, useState } from 'react';

import {
    ButtonsComponentPage,
    IntentChallenged,
    MsisdnUpdate,
    PaymentCompleted,
    PaymentRequestData,
    PaymentRequestFinalize,
    PaymentRequestUpdate,
    isIntentChallenged,
    isMsisdnUpdate,
    isPaymentCompleted,
    isPaymentRequestData,
    isIntentFailed, IntentFailed, WalletLayoutReady, isWalletLayoutReady, isPaymentRequestUpdate
} from './Types';
import { PaymentButtons as Buttons } from 'payments-ui-components/components/types';
import './Styles.css';
import { ZAHL_EINFFACH_PAYMENT_BUTTON } from 'src/features/pekunia/constants';
import { Trans, useTranslation } from 'react-i18next';
import WalletButtonStripe from "./WalletButtonStripe";

export default function PaymentButtons(buttonsComponentPage: ButtonsComponentPage) {
    const PayrailsWalletProvider: string = "Payrails";
    const StripeWalletProvider: string = "Stripe";

    const [walletProvider, setWalletProvider] = useState("")

    const { t, i18n } = useTranslation();
    const [context, setContext] = useState<unknown>();

    const [payment, setPayment] = useState<'apple_pay' | 'google_pay' | 'hosted_session'>();
    const [layoutReady, _setLayoutReady] = useState<boolean>(false);
    const layoutReadyRef = useRef(layoutReady);
    const setLayoutReady = data => {
        layoutReadyRef.current = data;
        _setLayoutReady(data);
    };

    const [walletLayoutReady, _setWalletLayoutReady] = useState<boolean>(false);
    const walletLayoutReadyRef = useRef(walletLayoutReady);
    const setWalletLayoutReady = data => {
        walletLayoutReadyRef.current = data;
        _setWalletLayoutReady(data);
    };

    const [walletAvailable, _setWalletAvailable] = useState<boolean>(false);
    const walletAvailableRef = useRef(walletAvailable);
    const setWalletAvailable = data => {
        walletAvailableRef.current = data;
        _setWalletAvailable(data);
    };

    const paymentSessionApi = 'session';
    const sessionPaymentButton = 'hosted_session';
    const [paymentButtons, _setPaymentButtons] = useState<Buttons[]>();
    const paymentButtonsRef = useRef(paymentButtons);
    const setPaymentButtons = data => {
        paymentButtonsRef.current = data;
        _setPaymentButtons(data);
    };
    const [style, setStyle] = useState<CSSProperties>();

    const [locale, setLocale] = useState<string>()
    const [amount, setAmount] = useState<number>()
    const [currency, setCurrency] = useState<string>()
    const [description, setDescription] = useState<string>()
    const [additionalInfo, setAdditionalInfo] = useState<string>()
    const popup = useRef<Window | null>();

    const messageHandler = async function (
        ev: MessageEvent<PaymentRequestData | PaymentRequestUpdate | PaymentRequestFinalize | IntentChallenged | MsisdnUpdate | PaymentCompleted | WalletLayoutReady>
    ) {
        if (isPaymentRequestData(ev)) {
            applyStyle();
            initializePaymentRequest(ev.data);
            return;
        }

        if (isWalletLayoutReady(ev)) {
            walletAvailableRequest(ev.data);
            return;
        }

        if (isPaymentRequestUpdate(ev)) {
            paymentRequestUpdatedHandler(ev.data);
            return;
        }

        if(isIntentChallenged(ev)){
            intentChallengedHandler(ev.data);
            return;
        }

        if (isIntentFailed(ev)) {
            intentFailedHandler(ev.data)
            return;
        }

        if(isMsisdnUpdate(ev)){
            window.parent.postMessage({isUpdateZahlEinfachRequest: true, msisdn: ev.data.msisdn, failureUrl: ev.data.failureUrl}, "*");
            return;
        }

        if(isPaymentCompleted(ev)){
            window.parent.postMessage({finalize: ev.data.finalize, success: ev.data.success}, "*");
            return;
        }
    };

    useEffect( () => {
        if (isWalletEnabled()) {
            setWalletProvider(buttonsComponentPage.provider)
        } else {
            setWalletLayoutReady(true);
        }

        setLayoutReady(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useLayoutEffect(() => {
        window.addEventListener('message', messageHandler);
        return () => {
            window.removeEventListener('message', messageHandler);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isWalletEnabled = () => {
        if (!buttonsComponentPage.brandAllowedPaymentButtons.includes(Buttons.Wallet) || !buttonsComponentPage.allowedPaymentButtons.includes(Buttons.Wallet)) {
            return false
        }
        return true
    }

    const onCheckout = () => {
        sessionRequest();
    };

    const onZahlEinfach = () => {
        const urlParams = new URLSearchParams({
            locale: locale!,
            amount: amount?.toString()!,
            currency: currency!,
            description: encodeURIComponent(description!),
            additional_info: encodeURIComponent(additionalInfo!)
        }).toString();

        popup.current = window.open("/msisdn?q=" + btoa(urlParams), "zahl-einfach");

        zahlEinfachRequest();
    };

    const sessionRequest = () => {
        window.parent.postMessage(
            {
                isRequest: true,
                paymentApi: paymentSessionApi,
                paymentButton: sessionPaymentButton,
                context: context
            },
            '*'
        );
    };

    const zahlEinfachRequest = () => {
        window.parent.postMessage(
            { isRequest: true, paymentButton: ZAHL_EINFFACH_PAYMENT_BUTTON, context: context },
            '*'
        );
    };

    const filterAllowedPaymentButtonsWithBrand = (requestedPaymentButtons: Buttons[]) => {
        if (requestedPaymentButtons) {
            let filteredArray = buttonsComponentPage.brandAllowedPaymentButtons.filter((value) =>
                requestedPaymentButtons.includes(value)
            );
            return filteredArray;
        }

        return buttonsComponentPage.brandAllowedPaymentButtons;
    };

    const walletAvailableRequest = (paymentParams: WalletLayoutReady) => {
        setWalletAvailable(paymentParams.isWalletAvailable)
        setWalletLayoutReady(true)
    };

    const initializePaymentRequest = (paymentParams: PaymentRequestData) => {
        if (!paymentParams) {
            return;
        }

        setLocale(paymentParams.locale);
        setAmount(paymentParams.amount);
        setCurrency(paymentParams.currency);
        setDescription(paymentParams.description);
        setAdditionalInfo(paymentParams.additionalInfo);

        i18n.changeLanguage(paymentParams.locale);
        setContext(paymentParams.context);

        if (paymentParams.allowedPaymentButtons) {
            setPaymentButtons(filterAllowedPaymentButtonsWithBrand(paymentParams.allowedPaymentButtons));
        } else {
            setPaymentButtons(buttonsComponentPage.brandAllowedPaymentButtons);
        }
    };

    const paymentRequestUpdatedHandler = (ev: PaymentRequestUpdate) => {
        i18n.changeLanguage(ev.locale);
        setContext(ev.context);
        setLocale(ev.locale);
        setAmount(ev.amount);
        setCurrency(ev.currency);
        setDescription(ev.description);
        setAdditionalInfo(ev.additionalInfo);
    };

    const intentChallengedHandler = (ev: IntentChallenged) => {
        if(popup){
            popup.current!.location.href = ev.intentChallengeUrl;
        }
    }

    const  intentFailedHandler = (ev: IntentFailed) => {
        if (!popup) {
            return;
        }

        if (ev.closeWindow) {
            popup.current!.close();
            return;
        }

        popup.current!.location.href = ev.redirectUrl;
    }

    const isZahlEinfachAllowed = () => {
        return paymentButtonsRef.current && paymentButtonsRef.current.includes(Buttons.ZahlEinfach);
    };

    const applyStyle = () => {
        let defaultBrandStyle: CSSProperties = {};

        if (buttonsComponentPage.presentationSettings.branding.button_color !== '') {
            defaultBrandStyle.backgroundColor = buttonsComponentPage.presentationSettings.branding.button_color;
            setStyle(defaultBrandStyle);
        }
    };

    return (
        <div>
            {layoutReadyRef.current && (
                <Grid className="checkout-buttons" container spacing={1}>
                    <Grid item xs style={{display: walletLayoutReadyRef.current ? 'block' : 'none'}}>
                        <Button style={{...style}} onClick={() => onCheckout()}>
                            {t("checkout")}
                        </Button>
                    </Grid>

                    {isWalletEnabled() && (
                        (walletProvider === StripeWalletProvider) && (
                            <Grid item xs style={{display: walletAvailableRef.current ? 'block' : 'none'}}>
                                <WalletButtonStripe {...buttonsComponentPage}/>
                            </Grid>
                        )
                    )}

                    {isZahlEinfachAllowed() && (
                        <Grid item xs style={{display: walletLayoutReadyRef.current ? 'block' : 'none'}}>
                            <Button className="ze-button" style={{...style}} onClick={() => onZahlEinfach()}></Button>
                        </Grid>
                    )}
                </Grid>
            )}
        </div>
    );
}