import HeronSansWoff2 from '../fonts/HeronSansCond-Regular.woff2';
import HeronSansBoldWoff2 from "../fonts/HeronSansCond-Bold.woff2";
import RobotoRegular from '../fonts/roboto/Roboto-Regular.ttf';
import RobotoMedium from '../fonts/roboto/Roboto-Medium.ttf';
import RobotoBold from '../fonts/roboto/Roboto-Bold.ttf';
import InterRegularWoff2 from '../fonts/Inter-latin-400.woff2';
import InterMediumWoff2 from '../fonts/Inter-latin-500.woff2';
import InterBoldWoff2 from '../fonts/Inter-latin-700.woff2';

export const heron = {
  fontFamily: 'HeronSansCond',
  fontStyle: 'normal',
  fontWeight: 'normal',
  src: `
local('HeronSansCond'),
local('HeronSansCond-Regular'),
url(${HeronSansWoff2}) format('woff2')
`,
};

export const heronBold = {
  fontFamily: 'HeronSansCond',
  fontStyle: 'normal',
  fontWeight: 'bold',
  src: `
local('HeronSansCond-Bold'),
url(${HeronSansBoldWoff2}) format('woff2')
`,
};

export const robotoRegular = {
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 'normal',
  src: `
local('Roboto'),
url(${RobotoRegular}) format('truetype')
`,
};

export const robotoMedium = {
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 500,
  src: `
local('Roboto-Medium'),
url(${RobotoMedium}) format('truetype')
`,
};

export const robotoBold = {
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 'bold',
  src: `
local('Roboto-Bold'),
url(${RobotoBold}) format('truetype')
`,
};

export const interRegular = {
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 400,
  src: `
local('Inter-Regular'),
url(${InterRegularWoff2}) format('woff2')
`,
};

export const interMedium = {
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 500,
  src: `
local('Inter-Medium'),
url(${InterMediumWoff2}) format('woff2')
`,
};

export const interBold = {
  fontFamily: 'Inter',
  fontStyle: 'bold',
  fontWeight: 700,
  src: `
local('Inter-Bold'),
url(${InterBoldWoff2}) format('woff2')
`,
};


