const colors = {
  // truphone blue tones
  blue1: '#2a9ec2',
  blue2: '#30819e',
  blue3: '#20667f',
  blue4: '#104c5f',
  blue5: '#01313f',

  // truphone neutral tones
  cream: '#f7f6f6',

  // truphone grey tones
  grey1: '#f3f6f6',
  grey2: '#e8ecee',
  grey3: '#dde3e5',
  grey4: '#d1d9dc',
  grey5: '#c6ccce',
  grey7: '#a1a0a1',
  grey9: '#767c7e',

  bluegrey1: '#90a4ae',
  bluegrey2: '#78909c',
  bluegrey3: '#607d8b',
  bluegrey4: '#546e7a',
  bluegrey5: '#455a64',
  bluegrey6: '#37474f',
  bluegrey7: '#263238',

  palegrey1: '#f4fafc',

  black: '#000000',
  white: '#ffffff',

  // theme main colors
  primary: '#2a9ec2',
  secondary: '#01313f',

  // truphone green tones
  green: 'green',

  // truphone red tones
  red: 'red',

  // truphone context colors
  success: '#04c453',
  error: '#e74243',
  warning: '#f57c00',

  tintBlue20: 'rgba(13, 49, 63, 0.2)',
  tintBlue30: 'rgba(13, 49, 63, 0.3)',
  tintBlue40: 'rgba(13, 49, 63, 0.45)',
  tintBlue60: 'rgba(13, 49, 63, 0.65)',
  tintBlue80: 'rgba(13, 49, 63, 0.85)',
  tintBlue100: '#0d313f',
};

export default colors;
