import React, {useEffect, useMemo} from "react";
import pseudoLocalization from 'pseudo-localization';

type Props = {
    children: React.ReactNode;
}

export default function PseudoLocalization(props: Props) {
    const searchParams: URLSearchParams = useMemo(
        () => new URLSearchParams(document.location.search),
        []
    );

    useEffect(() => {
        if (searchParams.get('pl')) {
            pseudoLocalization.start();
        }

        return () => {
            if (pseudoLocalization.isEnabled()) {
                pseudoLocalization.stop()
            }
        };
    }, [searchParams]);

    return <>{ props.children }</>
}