import {PresentationSettings} from "src/domain/PresentationSettings";
import {Intent} from "./Intent";

export class IntentPage {

    intent: Intent;
    presentationSettings: PresentationSettings;
    tenantId: string;
    customerId: string;
    locale: string;
    returnUrl: string;
    successCallBack: Function;
    pageBack: Function;
    instrumentTypes: string[];
    
    constructor() {
        this.intent = new Intent();
        this.presentationSettings = new PresentationSettings();
        this.tenantId = "";
        this.customerId = "";
        this.locale = "";
        this.returnUrl = "";
        this.successCallBack = () => {};
        this.pageBack = () => {};
        this.instrumentTypes = [];
    }
}