import Grid from '@mui/material/Grid';
import { Routing } from './components/Navigation';
import {BrowserRouter as Router, useSearchParams} from 'react-router-dom';
import theme from './theme/paywallTheme';
import PseudoLocalization from "./components/PseudoLocalization";
import { CookiesProvider } from 'react-cookie';
import { ThemeProvider } from '@mui/system';

function App() {
  return (
      <CookiesProvider>
        <PseudoLocalization>
          <Router>
              <ThemeProvider theme={theme}>
                <Grid container>
                  <Grid item style={{ flex: 1, width: '100%' }}>
                    <Routing />
                  </Grid>
                </Grid>
            </ThemeProvider>
          </Router>
        </PseudoLocalization>
      </CookiesProvider>
  );
}

export default App;