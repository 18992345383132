import { InstrumentHostedSession } from "src/domain/HostedSession";
import { useLayoutEffect } from "react";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";

export default function SetupPageHeader({instrumentHostedSession} : {instrumentHostedSession : InstrumentHostedSession}) {
    const navigate = useNavigate();

    const loadFavicon = (instrumentHostedSession : InstrumentHostedSession) => {
        const link = document.createElement('link');
        link.id = 'favicon';
        link.rel = 'shortcut icon';
        document.head.appendChild(link);

        if (instrumentHostedSession.presentation_settings.assets.favicon.indexOf("://") > 0) {
            (link as HTMLLinkElement).href = instrumentHostedSession.presentation_settings.assets.favicon;
        } else {
            (link as HTMLLinkElement).href = `/${instrumentHostedSession.presentation_settings.assets.favicon}-32x32.png`;
        }
    }

    const logo = instrumentHostedSession.presentation_settings.assets.logo.indexOf('://') > 0 ?
        instrumentHostedSession.presentation_settings.assets.logo : require(`src/assets/${instrumentHostedSession.presentation_settings.assets.logo}`);

    const setBackgroundColor = (instrumentHostedSession: InstrumentHostedSession) => {
        document.body.style.backgroundColor = instrumentHostedSession.presentation_settings.branding.background_color;
    }

    useLayoutEffect( () => {
        loadFavicon(instrumentHostedSession);
        setBackgroundColor(instrumentHostedSession);
    }, [instrumentHostedSession])
    
    return (
      <>
        <div style={{maxHeight: "30px", display: "flex", alignItems: "center", marginBottom: "30px"}}>
            <div id="backButton" style={{minHeight: "30px", display: "flex", alignItems: "center", float:"left", marginRight: "12px", cursor: "pointer"}} onClick={() => navigate(-1)}>
                <svg width="12" height="12" viewBox="0 0 16 16"><path d="M3.417 7H15a1 1 0 0 1 0 2H3.417l4.591 4.591a1 1 0 0 1-1.415 1.416l-6.3-6.3a1 1 0 0 1 0-1.414l6.3-6.3A1 1 0 0 1 8.008 2.41z" fill-rule="evenodd"></path></svg>
            </div>
            <div id="logo" style={{float: "left", minWidth: "100px", maxHeight: "30px"}}>
                <img className="branding-logo" width="auto" height="auto" style={{maxHeight: "30px"}} src={logo} alt="brandingLogo"></img>
            </div>
            <div id="backLabel" style={{float: "left", fontWeight:500, fontSize: "14px"}}>
                <span>{t("back")}</span>
            </div>
        </div>
        
        <div className="lg-content" style={{fontSize: "20px", fontWeight: 500, marginBottom: "30px"}}>{t("setupPageTitle")}</div>
      </>
    );
}

